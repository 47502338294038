import Splide from '@splidejs/splide';

import ScrollReveal from 'scrollreveal';


const initSlider = () => {
	if (document.getElementsByClassName('splide').length < 0) {
		document.addEventListener( 'DOMContentLoaded', function() {
			const splide = new Splide( '.splide', {
			  type   : 'loop',
				padding: '20rem',
			  // gap: '1rem',
				breakpoints: {
	    640: {
				padding: '2rem',
	    },
		},
			} );
	    splide.mount();
	  } );
	}
}

const initScrollReveal = () => {
	ScrollReveal().reveal('.js-reveal');
}

const init = () => {
	initSlider();
	initScrollReveal();
}

init();
